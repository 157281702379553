import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from 'grommet';

import ImgixImage from '../ImgixImage';
import TagOverlay from '../TagOverlay';
import SmartLink from '../SmartLink';
import ImageCaption from '../ImageCaption';

const ImageContentModule = ({
  image,
  content,
  fit,
  fill = true,
  useNaturalSize,
  tagOverlays = [],
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  link,
  srcSetOptions = { q: 75, auto: ['compress', 'format'], cs: 'srgb' },
  srcSetRanges = {},
  options = { q: 75, auto: ['compress', 'format'], cs: 'srgb' },
  quality,
  width,
  height,
  badgePosition = 'top-left',
  inSwiper = false,
  sizes,
  preload,
  showCaption,
  captionTheme,
  styleProps: stylePropsString,
  ...rest
}) => {
  const styleProps = stylePropsString ? JSON.parse(stylePropsString) : {};
  const imageObj = content ? content.image : image;
  const positionHeight =
    maxHeight || minHeight
      ? {
          max: maxHeight ? maxHeight : useNaturalSize ? 'fit-content' : '100%',
          min: minHeight ? minHeight : maxHeight ? undefined : 'fit-content',
        }
      : useNaturalSize
      ? 'fit-content'
      : '100%';
  const positionWidth =
    maxWidth || minWidth
      ? {
          max: maxWidth ? maxWidth : useNaturalSize ? 'fit-content' : '100%',
          min: minWidth ? minWidth : 'fit-content',
        }
      : useNaturalSize
      ? 'fit-content'
      : '100%';
  const mergedOptions = {
    ...options,
    q: quality ? parseInt(quality) : options.quality,
    w: width ? parseInt(width) : options.w,
    h: height ? parseInt(height) : options.h,
  };
  const mergedSrcSetOptions = {
    ...srcSetOptions,
    ...(quality || options.quality
      ? { q: quality ? parseInt(quality) : options.quality }
      : {}),
    ...(width || options.w ? { w: width ? parseInt(width) : options.w } : {}),
    ...(height || options.h
      ? { h: height ? parseInt(height) : options.h }
      : {}),
  };

  const imageProps = {
    fit,
    srcSetOptions: mergedSrcSetOptions,
    srcSetRanges,
    options: mergedOptions,
    height: positionHeight,
    width: positionWidth,
  };
  const to = link
    ? link.linktype === 'story' && link.cached_url !== ''
      ? `/${link.cached_url}`
      : link.url
    : undefined;

  const caption = imageObj.name || imageObj.title || imageObj.alt;

  return (
    <Box
      height={positionHeight || height}
      width={positionWidth || width}
      fill={useNaturalSize ? false : fill}
      className="imagecontentmodule-box"
      {...rest}
    >
      <Box
        as="figure"
        height={positionHeight || height}
        width={positionWidth || width}
        fill={useNaturalSize ? false : fill}
        style={{
          marginInlineStart: 0,
          marginInlineEnd: 0,
          marginBlockStart: 0,
          marginBlockEnd: 0,
        }}
      >
        <SmartLink to={to} fill={useNaturalSize ? false : fill}>
          <Stack fill anchor={badgePosition}>
            <Box
              height={positionHeight}
              width={positionWidth}
              fill={useNaturalSize ? false : fill}
            >
              <ImgixImage
                alignSelf="start"
                key={imageObj.id}
                src={
                  imageObj.originalSrc ||
                  imageObj.original_src ||
                  imageObj.filename ||
                  imageObj.src
                }
                fit={fit}
                fill={useNaturalSize ? false : fill}
                title={imageObj.title}
                alt={imageObj.name || imageObj.alt || imageObj.title}
                className={inSwiper ? 'swiper-lazy' : undefined}
                sizes={sizes}
                {...imageProps}
                style={styleProps}
                lazy={!preload}
                loading={preload ? 'eager' : 'lazy'}
              />
            </Box>
            {tagOverlays.length > 0 && (
              <Box pad="small">
                {tagOverlays.map((t, i) => (
                  <TagOverlay key={`${t.text}-${i}`} {...t} />
                ))}
              </Box>
            )}
          </Stack>
        </SmartLink>
      </Box>
      {caption && showCaption && (
        <ImageCaption caption={caption} theme={captionTheme} />
      )}
    </Box>
  );
};

ImageContentModule.propTypes = {
  content: PropTypes.object,
  image: PropTypes.object,
  fit: PropTypes.string,
  fill: PropTypes.bool,
  useNaturalSize: PropTypes.bool,
  tagOverlays: PropTypes.array,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  srcSetOptions: PropTypes.object,
  srcSetRanges: PropTypes.object,
  options: PropTypes.object,
  quality: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideOnMobile: PropTypes.bool,
  hideOnDesktop: PropTypes.bool,
  badgePosition: PropTypes.string,
  inSwiper: PropTypes.bool,
  sizes: PropTypes.string,
  showCaption: PropTypes.bool,
  preload: PropTypes.bool,
  styleProps: PropTypes.string,
};

export default memo(ImageContentModule);
