import React from 'react';
import { Text, Box } from 'grommet';
import TextTitle from './TextTitle';

type Theme = 'default' | 'title' | 'small-title';

function getThemeColor(theme: Theme) {
  switch (theme) {
    case 'title':
      return 'black';
    case 'small-title':
      return 'black';
    default:
      return 'dark-3';
  }
}
function getThemeSize(theme: Theme) {
  switch (theme) {
    case 'title':
      return 'small';
    case 'small-title':
      return 'xsmall';
    default:
      return 'small';
  }
}

export default function ImageCaption({ caption, theme }) {
  const color = getThemeColor(theme);
  const size = getThemeSize(theme);

  switch (theme) {
    case 'title':
      return (
        <Box margin={{ vertical: 'medium' }} width="100%">
          <TextTitle as="figcaption" center>
            {caption}
          </TextTitle>
        </Box>
      );
    case 'small-title':
      return (
        <Box margin={{ vertical: 'small' }} width="100%">
          <TextTitle as="figcaption" size={size} center>
            {caption}
          </TextTitle>
        </Box>
      );
    default:
      return (
        <Text
          size={size}
          as="figcaption"
          margin={{ vertical: 'small' }}
          color={color}
        >
          {caption}
        </Text>
      );
  }
}
