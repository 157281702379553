import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';

import ImageContentModule from './ImageContentModule';
import { getProductPath } from '../../lib/product';
import findImage from '../../lib/findImage';

const ImageContentModuleWithProduct = (props) => {
  const related = props.relatedProduct
    ? props.relatedProduct.plugin
      ? get('item', props.relatedProduct)
      : props.relatedProduct
    : null;

  const productUrl = related ? getProductPath(related) : undefined;

  const link =
    props.link.linktype === 'story' && props.link.cached_url !== ''
      ? `/${props.link.cached_url}`
      : props.link.url
      ? props.link.url
      : productUrl
      ? { url: productUrl }
      : props.link.linktype === 'story'
      ? `/${props.link.cached_url}`
      : props.link.url;

  return (
    <ImageContentModule
      {...props}
      link={link}
      tagOverlays={
        props.hideRelatedProductBadge
          ? []
          : [
              {
                text: related.title,
                imageUrl:
                  related.collectionImageUrl ||
                  get('media', related) ||
                  get('images', related)
                    ? findImage(related.media || related.images, '_grid')
                    : undefined,
                link: productUrl,
              },
            ]
      }
    />
  );
};

ImageContentModuleWithProduct.propTypes = {
  content: PropTypes.object,
  image: PropTypes.object,
  fit: PropTypes.string,
  relatedProduct: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideRelatedProductBadge: PropTypes.bool,
};

export default ImageContentModuleWithProduct;
