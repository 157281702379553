import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import styled from 'styled-components';

import ImgixImage from './ImgixImage';
import SmartLink from './SmartLink';

const TagText = styled(Text)`
  font-weight: 500;
  letter-spacing: 1px;
  font-size: ${(p) => (p.size === 'small' ? '8px' : '11px')};
  ${(p) => (p.size === 'small' ? 'line-height: 11px;' : '')}
  & > * {
    font-weight: 500;
  }
`;

const TagOverlay = ({ size = 'medium', imageUrl, text, link }) => {
  const isSmall = size === 'small';
  const imageSize = isSmall ? '15px' : '30px';

  return (
    <SmartLink to={link} plain>
      <Box
        background="white"
        border={{ side: 'all', color: 'black', size: 'small' }}
        pad={{
          vertical: isSmall ? 'xxsmall' : 'xsmall',
          horizontal: isSmall ? 'xsmall' : 'small',
        }}
        direction="row"
        gap="xsmall"
        align="center"
        flex={false}
      >
        {imageUrl && (
          <Box
            height={imageSize}
            width={imageSize}
            flex={{ shrink: 0, grow: 0 }}
          >
            <ImgixImage
              src={imageUrl}
              options={{ q: 75, w: isSmall ? 50 : 100 }}
              alt={text}
              lazy
              fill
            />
          </Box>
        )}
        <Box flex={{ shrink: 1, grow: 1 }}>
          <TagText size={isSmall ? 'small' : 'medium'}>{text}</TagText>
        </Box>
      </Box>
    </SmartLink>
  );
};

TagOverlay.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(TagOverlay);
